export const firstTeamWords = [
  "Flaming",
  "Jumping",
  "Robo",
  "Dancing",
  "Sticky",
  "Fluffy",
  "Glittery",
  "Dead",
  "Slippery",
  "Wiley",
  "Terrible",
  "Famous",
  "Lush",
  "Saucy",
  "Spiky",
  "Hairy",
  "Salty",
  "Crispy",
  "Cranky",
  "Wet",
];

export const secondTeamWords = [
  "Dragons",
  "Bananas",
  "Tourists",
  "Rockstars",
  "Worms",
  "Armadillos",
  "Sailors",
  "Seagulls",
  "Presidents",
  "Djs",
  "Pirates",
  "Warriors",
  "Peanuts",
  "Psychics",
  "Toddlers",
  "Bankers",
  "Socks",
  "Pugs",
  "Skulls",
  "Pillows",
  "Slugs",
  "Doctors",
];
